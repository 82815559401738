import React from "react";

const Accessibility=()=>{
    return(
        <>
        <h6>Accessibility</h6>
        </>
    )
}

export default Accessibility;