import React from "react";

const Notification=()=>{
    return(
        <>
        <div className="top-heading">
            <h4>Notification</h4>
        </div>
        </>
    )
}

export default Notification;