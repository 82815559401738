import React from "react";

const Cookie=()=>{
    return(
        <>
        <h6>Cookie</h6>
        </>
    )
}

export default Cookie;