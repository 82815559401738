import React from "react";

const Privacy=()=>{
    return(
        <>
        <h6>Privacy</h6>
        </>
    )
}

export default Privacy;