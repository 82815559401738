import React from "react";

const Tos=()=>{
    return(
        <>
        <h6>Tos</h6>
        </>
    )
}

export default Tos;