import React from "react";

const Error=()=>{
    return (
        <>
        <h2>No such page found</h2>
        </>
    )
}

export default Error;
