import React from "react";

const AddInfo=()=>{
    return(
        <>
        <h6>AddInfo</h6>
        </>
    )
}

export default AddInfo;